import React, { FC } from 'react';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { Device } from '../../types';
import { LabelValue } from '../../../../components/LabelValue/LabelValue';

interface GeneralInfoProps {
  device?: Device;
}

export const GeneralInfo: FC<GeneralInfoProps> = ({ device }) => {
  return (
    <>
      {device && (
        <div id={'device-general-info'}>
          <Row>
            <Col xs={12}>
              <LabelValue
                value={device.identifier}
                label={'Device ID'}
                copyable
              />
            </Col>
            <Col xs={12}>
              <LabelValue
                value={device.productProfile?.name}
                label={'Product Profile'}
                copyable
              />
            </Col>
            <Col xs={12}>
              <LabelValue value={device.createdAt} label={'Generated'} />
            </Col>
            <Col xs={12}>
              <LabelValue value={device.activatedAt} label={'Activated'} />
            </Col>
            <Col xs={12}>
              <LabelValue value={device.reportedAt} label={'Reported'} />
            </Col>
            {device.certificateUuid && (
              <Col xs={12}>
                <LabelValue value=" " label={'Related Certificate'} />
                <Link
                  to={{
                    pathname: '/inventory/certificates',
                    search: `?uuid=${device.certificateUuid}`,
                  }}
                >
                  <FontAwesomeIcon className="mr-2" icon={faFile} />
                  {device.certificateUuid}
                </Link>
              </Col>
            )}
          </Row>
        </div>
      )}
    </>
  );
};
